import React, { useEffect, useState, useRef, useContext } from 'react';
import './chat-app.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionData } from 'react-firebase-hooks/firestore';

import { useSearchParams } from 'react-router-dom';
import { query, updateDoc } from 'firebase/firestore';

import EcclesiaLogo from '../assets/PNG-01-removebg-preview.png';
import { APIAuthContext } from '../contexts/api-auth.context';
import Modal from 'react-modal';

firebase.initializeApp({
  // apiKey: "AIzaSyCNFB-DXz74H2Gny5YXywc2jg7ajMVPQlw",
  // authDomain: "ecclesia-chat-app.firebaseapp.com",
  // projectId: "ecclesia-chat-app",
  // storageBucket: "ecclesia-chat-app.appspot.com",
  // messagingSenderId: "391509155732",
  // appId: "1:391509155732:web:dd03bc51d7d583320eb981"

  apiKey: "AIzaSyB9_Y_v1BPLpVUE-SNWSX6aFslQXNny0OQ",
    authDomain: "parish-navigator.firebaseapp.com",
    projectId: "parish-navigator",
    storageBucket: "parish-navigator.appspot.com",
    messagingSenderId: "922727544184",
    appId: "1:922727544184:web:a83b7ac82f9867cf46848d",
    measurementId: "G-YYQTP9ZJ54"
})

const auth = firebase.auth();
const firestore = firebase.firestore();


function ChatApp() {
  const [queryParams] = useSearchParams();
  const [chatId, setChatId] = useState(null);
  const [topicName, setTopicName] = useState('');
  const [subscribers, setSubscribers] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    console.log(queryParams.get('chat'));
    setChatId(queryParams.get('chat'));
  })

  

  useEffect(() => {
    if(chatId !== null)
    {
      const chatRef = firestore.collection('chats').doc(chatId);
      chatRef.get().then((doc) => {
      setTopicName(doc.data().subject);
      setSubscribers(doc.data().notifications)
    })
    }
  }, [chatId])

  const subscribeNotifications = async(currentList, email) => {
    const chatRef = firestore.collection('chats').doc(chatId);
    await updateDoc(chatRef, {
      notifications: [...currentList, email]
    });
    setSubscribers([...currentList, email]);
    setModalOpen(false);
  }

  useEffect(() => {
    console.log("Change detected");
    if((subscribers !== null) && (auth.currentUser !== null)){
      const cuid = auth.currentUser.email;
      if(!subscribers.includes(cuid))
      {
        setModalOpen(true);

        // if(window.confirm("Would you like to subscribe to this chat (you will receive email notifications about any updates)")) {
        //   subscribeNotifications(subscribers, auth.currentUser.email);
        // } else {
        //   console.log("okay");
        // }
      }
    }
  }, [subscribers, auth.currentUser])

  const [user] = useAuthState(auth);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  if(chatId !== null)
  {
    return (
        <div className="App">
          <Modal isOpen={modalOpen} style={customStyles} onRequestClose={() => setModalOpen(false)} contentLabel='Notifications'>
            <h2>Stay updated</h2>
            <hr />
            <p style={{color: "black"}}>Stay updated about this conversation, by subscribing to this chat</p>

            <button className='btn btn-primary' onClick={() => subscribeNotifications(subscribers, auth.currentUser.email)}>Subscribe</button>
            <button className='btn btn-secondary' onClick={() => setModalOpen(false)}>No Thanks</button>
          </Modal>
          <header>
            <img src={EcclesiaLogo} alt="" />
            <h3>{topicName}</h3>
            <SignOut />
          </header>
    
          <section>
            {user ? <ChatRoom chatid={chatId} topicName={topicName} notificationsList={subscribers}/> : <SignIn />}
          </section>
        </div>
      );
  }
}

function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }
  return (
    <button className='sign-in' onClick={signInWithGoogle}>Sign in with Google</button>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function ChatRoom({chatid, topicName, notificationsList}) {
  const dummy = useRef();
  const messagesRef = firestore.collection('chats').doc(chatid).collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(100);

  const {authCreds} = useContext(APIAuthContext);

  const [messages] = useCollectionData(query);
  console.log(messages);

  const [formValue, setFormValue] = useState('');

  const sendMessage = async(event) => {
    event.preventDefault();

    const { uid, photoURL } = auth.currentUser;
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    });

    var headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(`${authCreds.username}:${authCreds.password}`))
    headers.set('content-type', 'application/json');
    await fetch('https://api.parishnavigator.com/send-email', {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        "content": ("New message in chat: " + topicName + ".\n View the conversation by clicking on the link: https://chat.parishnavigator.com/chat?chat="+chatid + "\nParishes should access the chat through the software - https://app.parishnavigator.com"),
        "subject": ("New Message in " + topicName),
        "parishName": "Chat",
        "recipients": notificationsList.filter(item => item !== auth.currentUser.email),
        "senderEmail": "general@parishnavigator.com",
        "file": ""
      })
    }).then((response) => console.log("send email response: ", response))

    setFormValue('');
    dummy.current.scrollIntoView({behaviour: 'smooth'});
  }

  return (
    <>
      <div>
        <div style={{height: "200px"}}></div>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <div style={{height: "200px"}}></div>
      </div>

      <span ref={dummy}></span>

      <form onSubmit={sendMessage}>
        <input value={formValue} onChange={(event) => setFormValue(event.target.value)}/>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;
  console.log(photoURL);
  const messageClass = uid === auth.currentUser.uid ? 'sent': 'received';

  console.log(text);
  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} height="50px" width="50px"/>
      <p>{text}</p>
    </div>
  );
}


export default ChatApp;
