import logo from './logo.svg';
import './App.css';
import ChatApp from './chat-app/chat-app.component';

import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path='chat' element={<ChatApp />}/>
    </Routes>
  )
}

export default App;